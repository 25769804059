/* INICIAR SESION */
.bg-green {
    background-color: #387e59;
}
.dropdown-item {
    background-color: #387e59;
    transition: background-color 0.3s ease, transform 0.3s ease;
    border: 1px solid transparent;
    text-decoration: none;
    color: #000;
  }
  
  .dropdown-item:hover {
    background-color: #2e5c45;
    border-color: #254834;
    transform: scale(1.1);
    color: #fff;
  }
.imgCarrusel {
    width: 100% !important;
    height: 700px !important;
    object-fit: cover;
}
.padding{
    padding-top: 10%;
}
.bigger-on-hover {
    font-size: 19px;
    transition: font-size 0.5s;
}

.my-link a {
    color: white;
}
.my-link a:hover{
    color:white;
}
.my-link-menu a {
    color: rgb(227, 227, 227);
}
.my-link-menu a:hover{
    color:rgb(227, 227, 227);
}
.my-link-verde a {
    color:  #387e59;
}
.footer {
    position: relative;
    bottom: 0px;
  }
.parent-container {
    min-height: 70vh;
}

html, body {
    height: 100%; /* Establece el alto del contenedor principal en 100% */
    margin: 0; /* Elimina los márgenes predeterminados */
  }
.main-container{
    min-height: 80%;
}

.my-link-verde a:hover{
    color: #387e59;
    font-weight: bold;

}
.milista li::marker{
    color:  #387e59 !important;
}

.my-link- p {
    color:  #387e59 !important;
}
.my-link- p:hover{
    color: #387e59 !important;
    font-weight: bold !important;

}
.boton{
    width: 100px;
}
.boton-login{
    width: 180px;
}
.x-trab{
    background-color: white;
}

.bigger-on-hover:hover {
    font-size: 25px;
}

.pointer {
    cursor: pointer;
  }
.hidden{
    display:none; 
}
.pwd-icon {
    color:  #387e59;
    bottom: 25%;
    right: 5%;
}
.pwd-icon:hover {
    filter: brightness(1.2);
}
.form-control {
    letter-spacing: 0.1rem !important;
    color:  black !important;
  }
.centrado{
	width: 900px;
	height: 600px;
	background-color: white;
	border:2px solid #387e59;
	/* centrar vertical y horizontalmente */
	position: absolute;
	top: 30%;
	left: 30%;
	margin: -25px 0 0 -25px;
}
.centrado2{
	width: 1000px;
	height: 1600px;
	background-color: white;
	border:2px solid #387e59;
	/* centrar vertical y horizontalmente */
	position: absolute;
	top: 30%;
	left: 30%;
	margin: -25px 0 0 -25px;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    color: #fff;
}

body {
    min-height: 100vh;
    background-color: dodgerblue;
}

.App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem 0.5rem;
}

section {
    width: 100%;
    max-width: 420px;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 1rem;
    background-color: rgba(0, 0, 0, 0.4);
}

form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-grow: 1;
    padding-bottom: 1rem;
}
a{
    color: black;
}

a:link, a:visited, a:active{
    text-decoration:none;
    color: #000;
}
a:hover{
    color: #000;
}

a:focus {
    color: black;
}

input[type="text"],
input[type="password"],
button,
textarea {
    font-family: 'Nunito', sans-serif;
    font-size: 22px;
    padding: 0.25rem;
    border-radius: 0.5rem;
}

label,
button {
    margin-top: 1rem;
}

button {
    padding: 0.5rem;
}

.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
    bottom: -10px;
}

.instructions>svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}


.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}

.line {
    display: inline-block;
}

/* CALENDARIO */
.react-calendar { 
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
   }
   .react-calendar__navigation button {
    color: #6f48eb;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
   }
   .react-calendar__navigation button:enabled:hover,
   .react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
   }
   .react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
   }
   abbr[title] {
    text-decoration: none;
   }
   /* .react-calendar__month-view__days__day--weekend {
    color: #d10000;
   } */
   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 6px;
   }
   .react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
   }
   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
   }
   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
   }
   .react-calendar__tile--active {
    background: #6f48eb;
    border-radius: 6px;
    font-weight: bold;
    color: white;
   }
   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
    background: #6f48eb;
    color: white;
   }
   .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
   }
   .react-calendar__tile--range {
    background: #f8f8fa;
    color: #6f48eb;
    border-radius: 0;
   }
   .react-calendar__tile--rangeStart {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    background: #6f48eb;
    color: white;
   }
   .react-calendar__tile--rangeEnd {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    background: #6f48eb;
    color: white;
   }

